import React from 'react';
import { Stack, Box, Typography } from '@mui/material';

const requisitos = [
      { description: 'Ser mexicana o mexicano.' },
      { description: 'Tener entre 18 y 29 años.' },
      { description: 'Contar con alguno de los siguientes estatus académicos:' },
      { description: 'Ser estudiante de Preparatoria o Bachillerato Técnico con al menos el 75% de los créditos cursados o,', indent: 1 },
      { description: 'Ser estudiante de una carrera universitaria o técnica de cualquier semestre/cuatrimestre o,', indent: 1  },
      { description: 'Ser una persona con estudios truncos de carrera universitaria o técnica.', indent: 1  },
      { description: 'Llenar el formato de registro electrónico en el sitio de Jóvenes que Impactan: www.jovenesqueimpactan.org.' },
      { description: 'Tener la firme intención de buscar y aplicar al menos a 3 ofertas laborales durante el transcurso de los 9 meses del programa.' },
      { description: 'Realizar una Entrevista en línea (con lineamientos que se describen en la convocatoria) con la intención de dar a conocer su perfil e intereses profesionales.' },
      { description: <Box>Realización de una <b>Prueba Psicométrica</b>.</Box> },
      { description: <Box>Firma de <b>Carta Compromiso</b>.</Box> },
    ]

export default function Requisitos() {
  return (
    <Stack sx={{
      mt: 2,
      boxShadow: '0px 0px 2px 1px #000000a1',
      backgroundColor: '#003a83',
      color: 'white',
      borderRadius: '16px',
      px: { xs: 1, md: 2 },
      py: 2,
      overflowWrap: 'anywhere',
      }}>
      <Typography variant="h5">Requisitos</Typography>
      <Stack alignItems="flex-start" sx={{ textAlign: 'left'}}>
        {requisitos.map((req, index) => (
          <Stack key={`red-${index}`} direction="row" sx={{ paddingLeft: req.indent ? req.indent * 4 : 0 }}><li></li><Stack sx={{pl: 1}}>{req.description}</Stack></Stack>
        ))}
      </Stack>
    </Stack>
  );
}
