import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const requisitos = [
  { 
    title: 'Curso en línea donde se dará capacitación en habilidades interpersonales y técnicas:',
    description: 'Revisa más detalles de las habilidades a desarrollar en la convocatoria de la sede a la que deseas aplicar.'
  },
  { 
    title: 'Una clase presencial/virtual al finalizar cada módulo con interacción grupal',
    description: 'Para efectos de las clases presenciales/virtuales, contaremos con dinámicas lúdicas y casos prácticos para fortalecer y aplicar los conocimientos adquiridos, esto de manera grupal para enriquecer las habilidades.',
  },
  { 
    title: 'Acompañamiento personal por un o una asesora para lograr la inserción laboral',
    description: 'Otro de los beneficios que ofrece el programa, es que cada beneficiaria o beneficiario será asignado y recibirá el acompañamiento de un asesor o asesora durante los meses de duración del curso para lograr la inserción laboral. En conjunto con el asesor o asesora, las y los beneficiarios recibirán apoyo en la elaboración de su CV, diseño de perfil en bolsas de trabajo y preparación de entrevistas.',
  },
  { 
    title: 'Conferencias y pláticas con el sector empresarial de manera presencial/virtual.',
    description: 'Contaremos con participación del sector empresarial a través de espacios en los que nos nutrirán con su experiencia y conocimientos en temas relacionados a la empleabilidad y habilidades necesarias para la vida.',
  },
];

const Descripcion = (props) => {
  // const {} = props;

  return (
    <Stack id="comolohacemos" alignItems="center" sx={{
      py: 10,
      mx: '5%',
      borderBottom: '1px solid #999'
      }}
    >
      <Stack  sx={{ maxWidth: '1200px', width: '100%' }} >
        <Typography variant="h5">¿Cómo lo hacemos?</Typography>
        <Box sx={{ textAlign: 'center', mb: 2 }}>El Programa Jóvenes que Impactan se integra por:</Box>
          {requisitos.map((req, index) => (
            <Accordion sx={{ backgroundColor: '#eee', color: '#444' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#444' }} />} aria-controls="panel1a-content" id="panel1a-header">
                <Box sx={{ fontSize: "16px", textAlign: 'left' }}>{req.title}</Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#f7f7f7'}}>
                <Stack direction="row" key={`req-${index}`}>
                  <Box sx={{fontSize: '16px', textAlign: 'left'}}>{req.description}</Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
      </Stack>
    </Stack>
  );
};

export default Descripcion;
