import './App.css';
import React from 'react';
import dayjs from 'dayjs';
import routes from './routers/routes';
import Router from './routers';
import Menu from './components/Menu';
import Alert from '@mui/material/Alert';
import Footer from './components/Footer';
import {Stack, Box} from '@mui/material';
import { useNavigate } from "react-router-dom";

// const publishDate = 'Tue Nov 15 2022 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate = 'Wed Feb 15 2023 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate = 'Wed Nov 08 2023 8:59:00 GMT-0600 (Central Standard Time)';
const publishDate = 'Tue Feb 06 2024 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate2 = 'Tue Nov 22 2022 08:59:00 GMT-0600 (Central Standard Time)';
// const publishDate3 = 'Tue Feb 28 2023 08:59:00 GMT-0600 (Central Standard Time)'

function App() {
  const currentDate = new Date();
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  
  // {open && dayjs(currentDate) > dayjs(publishDate2) && 
  //   <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
  //     <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
  //       <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria León</Box>
  //       <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
  //       <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.leon}#documento3`)}>aquí</Box>
  //     </Stack>
  //   </Alert>
  // }
  return (
    <div className="App">
      {open && dayjs(currentDate) > dayjs(publishDate) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria CDMX</Box>
            {/* <Box> - Ya está publicada la lista de los candidatos selecionados para la fase de pruebas psicométricas. Puedes verla </Box> */}
            <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.cdmx}#documento3`)}>aquí</Box>
          </Stack>
        </Alert>
      }
      {open && dayjs(currentDate) > dayjs(publishDate) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria Puebla</Box>
            {/* <Box> - Ya está publicada la lista de los candidatos selecionados para la fase de pruebas psicométricas. Puedes verla </Box> */}
            <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.puebla}#documento3`)}>aquí</Box>
          </Stack>
        </Alert>
      }
      {/* {open && dayjs(currentDate) > dayjs(publishDate3) && 
        <Alert variant="filled" severity="info" onClose={() => setOpen(false)}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <Box sx={{ fontWeight: 'bold', mr: 1/2 }}>Convocatoria Barrio Adentro</Box>
            <Box> - Ya está publicada la lista de los candidatos finales. Puedes verla </Box>
            <Box sx={{ ml: 1/2, cursor: 'pointer', color: 'aquamarine', textDecoration: 'underline' }} onClick={() => navigate(`${routes.barrioadentro}#documento3`)}>aquí</Box>
          </Stack>
        </Alert>
      } */}
      <Menu />
      <Stack sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
      }}>

        <Router />
      </Stack>
      <Footer />
    </div>
  );
}

export default App;
