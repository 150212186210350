import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Stack, Box, Divider } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/jqilogo.png';
import avisoprivacidad from '../assets/avisodeprivacidad.pdf';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/material/styles';
import ComponentFileViewer from '../ComponentFileViewer';

const Logo = styled('img')({
  maxWidth: '100%',
  maxHeight: '48px',
  objectFit: 'contain',
});

const A = styled('a')({
  color: 'inherit',
});

const A2 = styled('a')({
  color: 'cyan',
  textAlign: 'left',
});

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleSelectFile = () => {
    setSelectedFile({
      label: "Aviso de Privacidad",
      src: "https://s3.amazonaws.com/lideresenmovimiento.s3/Aviso+de+Privacidad.pdf",
      type: 'PDF',
    },);
    setOpen(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#003A83' }}>
        <Toolbar
          variant="dense"
          sx={{ justifyContent: 'space-evenly', fontSize:'14px', zIndex: 1100, flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Logo src={logo} alt="Jóvenes que Impactan Logo" />
          <Stack
            direction={{xs: 'column', md: 'row' }}
            spacing={1}
            p={2}
            alignItems="center"
            divider={<Divider orientation="horizontal" flexItem light sx={{ borderColor: 'rgb(255 255 255 / 13%)' }} />}
          >
            <Stack sx={{ flex: {xs: '100%', md: '32%'}}}>
              <A2 href="mailto:info@jovenesqueimpactan.org">info@jovenesqueimpactan.org</A2>
              <Box sx={{ textAlign: 'left'}}>
                Esteros 75 Col. Las Águilas, C.P. 01710. Álvaro Obregón. Ciudad de México
              </Box>
            </Stack>
            <Stack sx={{ flex: {xs: '100%', md: '32%' }}}>
              <Box>Síguenos en redes sociales</Box>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <A target="_blank" href="https://www.facebook.com/J%C3%B3venes-que-Impactan-119189806145214/" rel="noreferrer"><FacebookIcon /></A>
                <A target="_blank" href="https://twitter.com/JQImpactan" rel="noreferrer"><TwitterIcon /></A>
                <A target="_blank" href="https://www.instagram.com/jovenes.que.impactan/" rel="noreferrer"><InstagramIcon /></A>
              </Stack>
            </Stack>
            <Stack sx={{ flex: {xs: '100%', md: '32%' }}}>
              <ComponentFileViewer
                type="PDF"
                label="Aviso de Privacidad"
                component="link"
                src={avisoprivacidad}
              
              />
            </Stack>
            {/* <Box sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSelectFile}>Aviso de Privacidad</Box>
            {open && (
              <FileViewer
                open={open}
                setOpen={setOpen}
                src={selectedFile.src}
                label={selectedFile.label}
                type={selectedFile.type}
              />
            )} */}
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
