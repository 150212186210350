import React from 'react';
import { Stack, Box, Typography, Button } from '@mui/material';
import adultCompressed from '../../../components/assets/Adult_compressed.png';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routers/routes';

const Video = styled('video')({
  maxWidth: '100%',
  maxHeight: `400px`,
});


const Conclusion = (props) => {
  // const {} = props;
  const navigate = useNavigate();

  return (
    <Stack id="conclusion" alignItems="center" sx={{
      py: 5,
      px: '5%',
      backgroundImage: `url(${adultCompressed})`,
      color: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      }}
    >
      <Stack  sx={{ maxWidth: '1200px', width: '100%' }} spacing={2} >
        <Typography variant="h5">Convocatoria de Jóvenes que Impactan</Typography>
        <Box sx={{ textAlign: 'justify' }}>
          Si estás interesada o interesado en participar, conoce todos los detalles de nuestra convocatoria:
        </Box>
        <Box>
          Convocatoria Jóvenes que Impactan
        </Box>
        <Stack spacing={1}>
          {/* <Button variant="contained" onClick={() => navigate(routes.leon)}>Convocatoria Sede León</Button> */}
          <Button variant="contained" onClick={() => navigate(routes.cdmx)}>Convocatoria Sede CDMX</Button>
          <Button variant="contained" onClick={() => navigate(routes.puebla)}>Convocatoria Sede Puebla</Button>
          {/* <Button variant="contained" onClick={() => navigate(routes.barrioadentro)}>Convocatoria Sede Barrio Adentro</Button> */}
        </Stack>
        {/* {openViewer && (
          <FileViewer
            open={openViewer}
            setOpen={setOpenViewer}
            src={selectedFile.src}
            label={selectedFile.label}
            type={selectedFile.type}
          />
        )} */}
        <Box sx={{ textAlign: 'justify' }}>
        {/* <Video id="video" src={"https://www.youtube.com/embed/RDuknXTSb-o"} controls playsInline muted loop /> */}
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/RDuknXTSb-o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Conclusion;
