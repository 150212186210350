import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FileViewer from '../../FileViewer';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import logo from '../../assets/jqilogo.png';
import routes from '../../../routers/routes';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const Logo = styled('img')({
  maxHeight: '50px',
  objectFit: 'contain',
});

const A = styled('a')({
  color: 'white',
  placeContent: 'center',
  fontSize: '1.25rem',
  whiteSpace: 'normal',
  textAlign: 'center',
  textTransform: 'none',
  textDecoration: 'none',
});

export default function ModalMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (route) => {
    route && navigate(route);
    setAnchorEl(null);
  };

  const handleGoToLogin = (type) => {
    if (type === 'disney') {
      window.open('https://convocatoriadisney.jovenesqueimpactan.org/login', '_blank');
    } else {
      window.open('https://convocatorialeon.jovenesqueimpactan.org/login', '_blank');
    }
  };

  const items = [
    { label: 'Inicio', route: routes.root },
    { label: 'Convocatoria CDMX', route: routes.cdmx },
    { label: 'Convocatoria Puebla', route: routes.puebla },
    // { label: 'Convocatoria León', route: routes.leon },
    // { label: 'Convocatoria Barrio Adentro', route: routes.barrioadentro },
    { label: 'OCC Mundial', link: 'https://www.occ.com.mx/' },
  ]

  return (
    <Stack sx={{display: {sx: 'block', md: 'none'}}}>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Drawer
        transitionDuration={{ enter: 400, exit: 200 }}
        open={open}
        onClose={handleClose}
        anchor="right"
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          '& .MuiDrawer-paper': { right: 0, left: 0, backgroundColor: 'transparent' }
        }}
      >
        <Stack
          sx={{
            backgroundColor: '#003a83e8',
            color: 'white',
            height: '100%',
            px: { xs: 2, sm: 3 },
            py: 1,
            flexGrow: 1,
          }}
        >
          <Stack flexGrow={1}>
            <Stack flexGrow={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: 48, mb: 2 }}>
              <Logo src={logo} alt="Jóvenes que Impactan Logo" />
              <IconButton edge="start" color="inherit" onClick={handleClose} sx={{ mr: 2 }}>
                <CloseIcon />
              </IconButton>
            </Stack>
            {items.map((item) => {
              if (item.link) {
                return <A href={item.link} target="_blank" rel="noreferrer">
                  <MenuItem
                    key={item.label}
                    onClick={() => handleClose(item.route)}
                    sx={{
                      placeContent: 'center',
                      fontSize: '1.25rem',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                    }}
                  >
                    {item.label}
                  </MenuItem></A>
              } else {
                return ( 
                  <MenuItem
                    key={item.label}
                    onClick={() => handleClose(item.route)}
                    sx={{
                      placeContent: 'center',
                      fontSize: '1.25rem',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                    }}
                  >
                    {item.label}
                  </MenuItem>
                )
              }})}
              {/* <Button
                variant="contained"
                onClick={handleGoToLogin}
                sx={{
                  alignSelf: 'center',
                  backgroundColor: '#fac147',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#db9b10'
                  }
                }}
              >
                ¿Ya empezaste tu aplicación? Continua Aquí
              </Button> */}
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
}
