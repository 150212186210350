import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// import leonImage from '../../../components/assets/leon_c.jpg'
import leonImage from '../../../components/assets/leon_c2.jpg'
import cdmxImage from '../../../components/assets/cdmx_c.jpg'
import pueblaImage from '../../../components/assets/puebla.jpg'
import barrioImage from '../../../components/assets/convocatoriabarrio2.jpeg'
import { useNavigate } from 'react-router-dom';
import routes from '../../../routers/routes';

const Logo = styled('img')({
  maxHeight: '70px',
});

const Img = styled('img')(({theme}) => ({
  maxWidth: '100%',
  maxHeight: 140,
  objectFit: 'contain',
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
}));

const convocatorias = [ 
  { title: 'Convocatoria CDMX 2024', active: true, img: cdmxImage, route: routes.cdmx },
  { title: 'Convocatoria Puebla 2024', active: true, img: pueblaImage, route: routes.puebla },
  // { title: 'Convocatoria León 2022', active: false, img: leonImage, route: routes.leon },
  // { title: 'Convocatoria Barrio Adentro 2022', active: false, img: barrioImage, route: routes.barrioadentro, backgroundPosition: '50% 10%' },
];

const Requisitos = (props) => {
  const navigate = useNavigate();
  // const {} = props;

  return (
    <Stack id="requisitos" alignItems="center" sx={{
      py: 10,
      px: '5%',
      }}
    >
      <Stack  sx={{ maxWidth: '1200px', width: '100%' }} spacing={2} >
        <Typography variant="h5">Programas de Jóvenes que Impactan</Typography>
        <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
          {convocatorias.map((conv) => (
            <Stack
              onClick={conv.active ? () => navigate(conv.route) : () => {}}
              sx={{
                flexShrink: 0,
                flex: {xs: '100%', md: '50%'},
                boxShadow: 7,
                borderRadius: 2,
                backgroundImage: `url(${conv.img})`,
                color: 'white',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: 200,
                width: '100%',
                backgroundPosition: conv.backgroundPosition || '50% 50%',
                // mx: 6,
                cursor: conv.active ? 'pointer' : 'default',
                '&:hover': {
                  transform: conv.active ? 'scale(1.05)' : 'none',
                  boxShadow: '0px 4px 5px -2px rgb(0 58 131 / 20%), 0px 7px 10px 1px rgb(0 58 131 / 14%), 0px 2px 16px 1px rgb(0 58 131 / 12%);'
                },
              }}>
                <Stack
                  sx={{
                    background: conv.active ? 'rgb(0 0 0 / 29%)' : '#8c8c8cd4',
                    p: 2,
                    height: '100%',
                    justifyContent: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h5">{conv.title}</Typography>
                </Stack>
              {/* <Img src={conv.img} /> */}
                {/* <Stack alignItems="flex-start" sx={{ textAlign: 'left'}}>
                  {conv.requirements.map((req) => (
                    <Stack direction="row"><li></li><Stack sx={{pl: 1}}>{req.description}</Stack></Stack>
                  ))}
                </Stack> */}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Requisitos;
