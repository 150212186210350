import React from 'react';
import { Dialog, DialogTitle, IconButton, Stack, Box, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const Video = styled('video')({
  maxWidth: '100%',
  maxHeight: `100%`,
});

const Iframe = styled('iframe')({
  height: '100%',
});

const CustomDialog = styled(Dialog)({
  // '& .MuiPaper-root': {
  //   width: '90%',
  //   height: `90%`,
  // },
});

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


const FileViewer = (props) => {
  const { open, setOpen, label, src, type } = props;
  const [loading, setLoading] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Handles IPHONE NOT SEETING DOCUMENTS
    if (open && src && type === 'PDF' && iOS()) {
      window.open(src, '_blank');
      setOpen(false);
    }
  }, [open])

  const handleLoad = () => setLoading(false);

  return (
    <CustomDialog onClose={handleClose} open={open} fullScreen maxWidth={false}>
      <DialogTitle sx={{ padding: '8px 24px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box sx={{ fontSize: '16px' }}>{label}</Box>
          <IconButton aria-label="close" onClick={handleClose}><CloseIcon /></IconButton>
        </Stack>
      </DialogTitle>
      <Stack flexGrow={1} sx={{ position: 'relative' }}>
      {loading && (
        <CircularProgress thickness={5} sx={{ position: 'fixed', top: '50%', left: '50%' }}/>
      )}
      {type === 'PDF' && src && (
        <Iframe
          title={label}
          loading="lazy"
          src={src}
          onLoad={handleLoad}
          onError={(err) => console.log(err)}
        />
      )}
      {type === 'Video' && src && (
        <Video
          src={src}
          controls
          onLoad={handleLoad}
        />
      )}
    </Stack>
    </CustomDialog>
  );
};

export default FileViewer;
