import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, IconButton } from '@mui/material';
import Descripcion from './Descripcion';
import QueEs from './QueEs';
import ComoLoHacemos from './ComoLoHacemos';
// import Requisitos from './Requisitos';
// import Calendario from './Calendario';
import Convocatorias from './Convocatorias';
import Conclusion from './Conclusion';
import {Helmet} from "react-helmet";
import stock from '../../components/assets/StockSnap_compressed.png'


const Home = (props) => {

  return (
    <Stack sx={{ width: '100%', marginTop: 0, flexGrow: 1 }}>
      <Helmet>
          <title>Jóvenes que Impactan- Inicio</title>
          <meta name="description" content="Programa Jóvenes que Impactan - Fundación Pro Universitaria" />
      </Helmet>
      <Stack sx={{ backgroundColor: '#181C2B', position: 'relative' }}>
        <Stack sx={{
            display: 'flex',
            height: 530,
            backgroundImage: `url(${stock})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
          }}>
            <Stack sx={{
              background: 'rgb(0 0 0 / 30%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', 
              height: 530,
           }}>
          <Stack sx={{color: 'white'}}>
            <Stack sx={{fontSize: 20}}>Nuestro objetivo es</Stack>
            <Stack sx={{fontSize: 32, fontWeight: 'bold'}}>ampliar la gama de</Stack>
            <Stack sx={{fontSize: 32, fontWeight: 'bold'}}>oportunidades para jóvenes</Stack>
          </Stack> 
          </Stack>
        </Stack>
      </Stack>
      <Descripcion />
      <QueEs />
      <ComoLoHacemos />
      <Convocatorias />
      {/* <Requisitos /> */}
      {/* <Calendario /> */}
      <Conclusion />
    </Stack>
  );
};

export default Home;
