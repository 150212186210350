import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const testimonios = [
      { nombre: 'Nancy Berenice López Alfaro',
        testimonio: `“El programa ha sido una gran oportunidad de crecimiento para mí tanto de
        manera personal como profesional. Gracias a él he tenido la oportunidad de
        abrir mi panorama, de mejorar mis habilidades sociales y entender el
        mundo de la negociación, Por lo que ahora me siento mejor preparada para
        empezar con mi vida laboral”`, },
      { nombre: 'Mauricio Ibarra Segura',
        testimonio: `“Me ha dejado muy satisfecho, gracias al programa me he dado cuenta de
        muchas aptitudes que son necesarias para sobresalir en cualquier ámbito,
        he ganado distintas habilidades y he logrado tener una diferente
        perspectiva de las acciones personales. En lo personal agradezco mucho la
        oportunidad de formar parte de jóvenes que impactan sin duda alguna está
        marcando mi desarrollo profesional y me va a ayudar a destacar no sólo en
        el ámbito laboral, sino también en el social y poder mejorar nuestro entorno”`,
      },
      { nombre: 'Diego Eduardo Frausto Hernández',
        testimonio: `“Puede parecer un simple programa, pero es hasta que estás en él y
        gracias a él que descubres y comprendes de mejor manera las cosas, no
        sólo en el ambiente laboral, sino en lo personal también. Quizá puedas
        pensar que estar en él te quita el tiempo, pero realmente es que estando en
        él descubres un tiempo para ti, te da una mejor perspectiva de la vida,
        gracias a su ayuda, algún día seré un joven que impacta”`,
      },
    ];

export default function Testimonios() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Stack sx={{my:8}} justifyContent="center" alignItems="center">
      <Typography variant="h5">Testimonios</Typography>
      <Stack sx={{my: 2}}>
        <Stack>{testimonios?.[activeStep]?.testimonio}</Stack>
        <Stack>-</Stack>
        <Stack sx={{color: 'gray'}}>{testimonios?.[activeStep]?.nombre}</Stack>
      </Stack>
      <MobileStepper
      variant="dots"
      steps={testimonios.length}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, flexGrow: 1 }}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === testimonios.length - 1}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </Button>
      }
    />
    </Stack>
  );
}
