import React from 'react';
import { Stack, Box, Typography, Button } from '@mui/material';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { styled } from '@mui/material/styles';

const A = styled('a')({
  // color: 'white',
  margin: '4px',
  textTransform: 'none',
  textDecoration: 'none',
});

const Calendario = (props) => {
  // const {} = props;

  const headers = [
    {title: 'Etapa'},
    {title: 'Fecha'},
  ]

  const rows = [
    { etapa: 'Lanzamiento de convocatoria', fecha: '11 de diciembre 2023' },
    { etapa: 'Cierre del registro de aspirantes', fecha: '19 de enero 2024' },
    { etapa: 'Publicación del listado de aspirantes a realizar Pruebas Psicométricas', fecha: '23 de enero 2024' },
    { etapa: 'Fecha límite de aplicación de Pruebas Psicométricas', fecha: '26 de enero 2024' },
    { etapa: 'Publicación de Candidatas y Candidatos Finales', fecha: '6 de febrero 2024' },
    { etapa: 'Confirmación de participación de Candidatas y Candidatos Finales y entrega de Carta compromiso', fecha: '6 y 7 de febrero 2024' },
    { etapa: 'Sesión de Bienvenida del Programa Jóvenes que Impactan', fecha: '10 de febrero 2024' },
    { etapa: 'Inicio del Programa Jóvenes que Impactan', fecha: '12 de febrero 2024' },
  ]

  return (
    <Stack id="calendario" alignItems="center" sx={{
      py: 10,
      }}
    >
      <Stack  sx={{ maxWidth: '1200px', width: '100%' }} spacing={2} >
        <Typography variant="h5">Calendario</Typography>
        {/* <A rel="noreferrer" target="_blank" href="https://calendar.google.com/calendar/u/0?cid=Y182MzM0ZDdhNDlhYmQ0MTY5YTg5NzRhNGY5MjA1M2UzNzFkZTI3MWFhMzJjODQyYmQyNWVjZTU4NjQyYjNjM2JmQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20">
          <Button variant="outlined">
            <CalendarTodayIcon sx={{ mr: 1 }}/>
            Agregar Fechas a tu Calendario
          </Button>
        </A> */}
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h.title} sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>{h.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
              {rows.map((r) => (
                <TableRow key={r.etapa}>
                  <TableCell>{r.etapa}</TableCell>
                  <TableCell>{r.fecha}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Stack>
    </Stack>
  );
};

export default Calendario;
