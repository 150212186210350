import * as React from 'react';
import {Box, Stack, Toolbar, AppBar} from '@mui/material';
import logo from '../assets/jqilogo.png';
import { styled } from '@mui/material/styles';
import ModalMenu from './ModalMenu';
import { useNavigate } from "react-router-dom";
import routes from '../../routers/routes';

const Logo = styled('img')({
  maxHeight: '50px',
});

const A = styled('a')({
  color: 'white',
  margin: '4px',
  textTransform: 'none',
  textDecoration: 'none',
});

const MenuItem = styled('a')({
  color: 'white',
  margin: '4px',
  textTransform: 'none',
  textDecoration: 'none',
  cursor: 'pointer',
});

export default function DenseAppBar() {
  let navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor:'#003A83' }}>
        <Toolbar variant="dense" sx={{ justifyContent: 'space-between',  py: 1, backgroundColor:'#003A83', zIndex: 1100 }}>
          <Logo
            src={logo}
            onClick={() => navigate(routes.root)}
            alt="Jóvenes que Impactan Logo"
            sx={{ cursor: 'pointer' }}
          />
          <Stack direction="row" sx={{ display: {xs: 'none', md: 'block'}}}>
            <MenuItem onClick={() => navigate(routes.root)}>Inicio</MenuItem>
            <MenuItem onClick={() => navigate(routes.cdmx)}>Convocatoria CDMX</MenuItem>
            <MenuItem onClick={() => navigate(routes.puebla)}>Convocatoria Puebla</MenuItem>
            {/* <MenuItem onClick={() => navigate(routes.leon)}>Convocatoria León</MenuItem> */}
            {/* <MenuItem onClick={() => navigate(routes.barrioadentro)}>Convocatoria Barrio Adentro</MenuItem> */}
            <A href="https://www.occ.com.mx/" target="_blank" rel="noreferrer">OCC Mundial</A>
          </Stack>
          <ModalMenu />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
