const routes = {
  // Root
  root: '/',
  cdmx: '/cdmx',
  puebla: '/puebla',
  leon: '/leon',
  barrioadentro: '/barrioadentro',
  repositorio: '/repositorio',
  contacto: '/contacto',
};

export default routes;
