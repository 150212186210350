import React from 'react';
import { Stack, Box, Typography } from '@mui/material';

const requisitos = [
      { description: 'Ser mexicana o mexicano y radicar en la Puebla.' },
      { description: 'Tener entre 18 y 29 años.' },
      { description: 'Contar con alguno de los siguientes estatus académicos: Ser estudiante de Preparatoria o Bachillerato Técnico con al menos el 75% de los créditos cursados o ser estudiante de una carrera universitaria o técnica de cualquier semestre/cuatrimestre o ser una persona con estudios truncos o egresada de carrera universitaria o técnica.' },
      { description: 'Tener un promedio igual o mayor de 8.0 ' },
      { description: 'Aprobar todas las fases del proceso de selección (detallado en esta convocatoria).' },
      { description: 'TENER LA FIRME INTENCIÓN DE LOGRAR LA INSERCIÓN LABORAL durante los 6 meses de duración del programa a través de alguna de estas opciones: En caso de no contar con empleo, aplicar al menos a 3 ofertas laborales y lograr insertarse en el mercado laboral o en caso de contar con empleo, tener un crecimiento dentro o fuera del trabajo actual o desarrollar un emprendimiento a través de la creación y ejecución de un plan de negocio.' },
      { description: 'TENER LA FIRME INTENCIÓN DE FINALIZAR LA TOTALIDAD DEL PROGRAMA, cumpliendo con todas las actividades que se soliciten durante los 6 meses de duración del mismo.' },
      { description: <Box>Realización de una <b>Prueba Psicométrica</b>.</Box> },
      { description: <Box>Firma de <b>Carta Compromiso</b>.</Box> },
    ]

export default function LemMs() {
  return (
    <Stack sx={{
      mt: 2,
      boxShadow: '0px 0px 2px 1px #000000a1',
      backgroundColor: '#003a83',
      color: 'white',
      borderRadius: '16px',
      px: { xs: 1, md: 2 },
      py: 2,
      overflowWrap: 'anywhere',
      }}>
      <Typography variant="h5">Requisitos</Typography>
      <Stack alignItems="flex-start" sx={{ textAlign: 'left'}}>
        {requisitos.map((req, index) => (
          <Stack key={`red-${index}`} direction="row"><li></li><Stack sx={{pl: 1}}>{req.description}</Stack></Stack>
        ))}
      </Stack>
    </Stack>
  );
}
