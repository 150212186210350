import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import logo from '../../../components/assets/jqilogoblack.png';
import { styled } from '@mui/material/styles';

const Logo = styled('img')({
  maxHeight: '120px',
  objectFit: 'contain',
});

const Descripcion = (props) => {
  // const {} = props;

  return (
    <Stack id="descripcion" alignItems="center" sx={{ py: 10, px: '5%'}}>
      <Stack  sx={{ maxWidth: '1200px', width: '100%' }} spacing={2} >
        <Typography variant="h5">¿Quiénes somos?</Typography>
        <Box sx={{ textAlign: 'justify' }}>
          <b>Fundación Pro Estudio A.C.</b>, es una donataria autorizada creada en 2016, que engloba una variedad de proyectos dirigidos a brindar a los jóvenes mejores habilidades para el empleo y apoyos para la exitosa conclusión de sus estudios universitarios. En esencia, nos dedicamos a cerrar la brecha entre oferta educativa y mercado laboral, en beneficio de los jóvenes.
        </Box>
        <Box sx={{ textAlign: 'justify' }}>
        Cuidamos que todos los proyectos estén alineados con nuestros objetivos sociales y académicos, que contemos con los recursos para ello, y que estemos continuamente innovando.
        </Box>
        {/* <Box sx={{ textAlign: 'center' }}>
          <b>Conoce más de</b>
        </Box> */}
        <Logo
          src={logo}
          alt="Jóvenes que Impactan"
        />
      </Stack>
    </Stack>
  );
};

export default Descripcion;
