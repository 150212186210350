import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const testimonios = [
      { nombre: 'Marco Antonio Lara Torres',
        testimonio: `“Este programa está muy completo, además de ser una herramienta que
        nos ayuda a prepararnos para la vida laboral. Es una buena experiencia
        compartir conocimientos con personas que quieren ser mejores día con día
        y que gracias a este programa estamos más cerca de conseguir el trabajo
        que tanto queremos”`,
      },
      { nombre: 'Mariana Scarlet Patlan Lira',
        testimonio: `“Es un proceso de mejoramiento constante en aquellos que somos parte del
        programa, jamás se deja de aprender y una vez dentro de este curso
        entiendes el significado de progresar en el aquí y ahora”`,
      },
    ];

export default function Testimonios() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Stack sx={{my:8}} justifyContent="center" alignItems="center">
      <Typography variant="h5">Testimonios</Typography>
      <Stack sx={{my: 2}}>
        <Stack>{testimonios?.[activeStep]?.testimonio}</Stack>
        <Stack>-</Stack>
        <Stack sx={{color: 'gray'}}>{testimonios?.[activeStep]?.nombre}</Stack>
      </Stack>
      <MobileStepper
      variant="dots"
      steps={testimonios.length}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, flexGrow: 1 }}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === testimonios.length - 1}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </Button>
      }
    />
    </Stack>
  );
}
