import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import fundacionProUniversitaria from '../../../components/assets/logoFPU.png';
import bump from '../../../components/assets/bump_compressed.png';
import { styled } from '@mui/material/styles';

const Logo = styled('img')({
  maxHeight: '70px',
});

const QueEs = (props) => {
  // const {} = props;

  return (
    <Stack id="quees" alignItems="center" sx={{
      py: 10,
      px: '5%',
      backgroundImage: `url(${bump})`,
      color: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      }}
    >
      <Stack  sx={{ maxWidth: '1200px', width: '100%' }} spacing={2} >
        <Typography variant="h5">¿Qué es Jóvenes que Impactan?</Typography>
        <Box sx={{ textAlign: 'justify' }}>
        Somos un programa que busca apoyar a jóvenes mexicanos entre 18 y 29 años en su búsqueda de oportunidades 
        laborales, capacitándolos durante 9 meses, con las habilidades interpersonales y técnicas que las empresas 
        están buscando. Nuestro objetivo es ampliar la gama de oportunidades para las y los jóvenes y asegurar que tengan las herramientas necesarias para seguir creciendo profesionalmente.
        </Box>
        <Box sx={{ textAlign: 'justify' }}>
        El <b>Programa Jóvenes que Impactan</b> es un proyecto posible gracias al generoso apoyo de Pathways to 
        Progress de Citi Foundation, Fomento Social de Citibanamex, "Formación para Trabajo Digno" de Nacional Monte 
        de Piedad, Fundación Coppel y Disney que, desde la temática de Formación para Trabajo Digno, buscan contribuir 
        a la inserción laboral y la mejora de calidad del empleo en el mundo.
        </Box>
      </Stack>
    </Stack>
  );
};

export default QueEs;
