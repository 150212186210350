import React from 'react';
import { Stack, Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import {Helmet} from "react-helmet";
import Requisitos from './Requisitos';
import Calendario from './Calendario';
import Testimonios from './Testimonios';
// import Image from '../../components/Image';
import FileViewer from '../../components/FileViewer';
import convocatoria from '../../components/assets/convocatoriabarrio.jpeg';
import convocatoriabarrioabierto from './convocatoriabarrioabierto.pdf'
import candidatosbarrio from './candidatosbarrio.pdf'
import resultadosFinales from './candidatosfinalesbarrio.pdf'
// import resultadosPrueba from './resultados_prueba.pdf'
// import resultadosPrueba2 from './resultados_prueba2.pdf'


const B = styled('span')({
  color: '#268c83',
  fontWeight: 'bold',
});

const Img = styled('img')(({theme}) => ({
  maxWidth: '100%',
  maxHeight: 140,
  objectFit: 'contain',
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
}));

const Video = styled('video')({
  maxWidth: '100%',
  maxHeight: `400px`,
});

const buttons = [
  {
    id: 1,
    label: "Convocatoria",
    src: convocatoriabarrioabierto,
    type: 'PDF',
  },
  {
    id: 2,
    label: "1. Resultados Pruebas Psicométricas",
    src: candidatosbarrio,
    variant: 'contained',
    color: 'secondary',
    type: 'PDF',
    date: 'Wed Feb 15 2023 08:59:00 GMT-0600 (Central Standard Time)'
  },
  {
    id: 3,
    label: "2. Resultados de Candidatas y Candidatos Finales",
    src: resultadosFinales,
    variant: 'contained',
    color: 'secondary',
    type: 'PDF',
    date: 'Tue Feb 28 2023 08:59:00 GMT-0600 (Central Standard Time)',
    // date: 'Tue Nov 15 2022 08:59:00 GMT-0600 (Central Standard Time)'
  },
];

export default function LemMs() {
  const [open, setOpen] = React.useState(false);
  const currentDate = new Date();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const { pathname, hash, key } = useLocation();

  React.useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          if (hash === '#documento2') {
            setSelectedFile(buttons[1]);
            setOpen(true);
          }
          if (hash === '#documento3') {
            setSelectedFile(buttons[2]);
            setOpen(true);
          }
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  const handleOpenFile = (button) => {
    setSelectedFile(button);
    setOpen(true);
  };

  const handleGoToApplication = () => {
    // let now = new Date();
    // if (dayjs(now) > dayjs('Mon Jan 10 2022 00:00:00 GMT-0600 (Central Standard Time)')) {
      window.open('https://convocatoriabarrioadentro.jovenesqueimpactan.org/apply/31', '_blank');
    // } else {
    //   alert("El Registro abre el 10 de Enero, 2022");
    // }
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        flexGrow: 1,
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Helmet>
          <title>Convocatoria Barrio Adentro - Jóvenes que Impactan</title>
          <meta name="description" content="Invitamos a jóvenes mexicanos a participar en el Programa Jóvenes que Impactan; que tengan entre 18 y 29 años" />
      </Helmet>
      <Stack
        sx={{
          backgroundImage: `url(${convocatoria})`,
          // color: 'white',
          height: {xs: 200, md: 400},
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 60%',
        }}
      />
      <Stack  sx={{py: 5,
        px: '5%', maxWidth: '1200px', width: '100%', boxSizing: 'border-box' }}>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5">Convocatoria Barrio Adentro 2022</Typography>
        </Stack>
        <Stack spacing={2} mb={2}>
          <Stack direction="row" justifyContent="space-around" sx={{ mt: 2}}>
            <Box sx={{ textAlign: 'justify' }}>
            Invitamos a jóvenes mexicanos a participar en el <b>Programa Jóvenes que Impactan</b>; que tengan entre 18
            y 29
            años y que cumplan con alguno de los siguientes estatus académicos: 1) Ser estudiante de Preparatoria o Bachillerato Técnico
            con al menos el 75% de los créditos cursados o 2) Ser estudiante de una carrera universitaria
            o técnica de cualquier semestre/cuatrimestre o 3) Ser una persona con estudios truncos o
            egresada de carrera universitaria o técnica, que residan actualmente en la Ciudad de México
            (zona centro) <b>y que tengan la firme
            intención de insertarse en el mercado laboral durante los 9 meses de duración del Programa</b>.
            </Box>
          </Stack>
          <Stack sx={{ mt: 2}}>
            <Typography variant="h5" mt={4}>¿De qué se trata?</Typography>
            <Box sx={{ textAlign: 'justify', mt: 2 }}>
            Curso on-line y presencial de 9 meses de las habilidades interpersonales y técnicas que
            requieren las empresas (6 meses de contenido de capacitación, equivalentes a 240 horas y
            3 meses de enfoque en inserción laboral). Cada módulo tiene duración de 1 mes, donde se
            capacitará a la beneficiaria o beneficiario a través de una plataforma en línea con videos,
            actividades y tareas, las cuales serán evaluadas de manera mensual.
            </Box>
          </Stack>
        </Stack>
        {/* <Video id="video_MS" src={"https://s3.amazonaws.com/lideresenmovimiento.s3/testimoniales_ms.mp4"} controls autoPlay playsInline muted loop /> */}
        <Requisitos />
        <Calendario />
        {/* <Typography variant="h5" mt={4}>Convocatoria</Typography>
        {buttons.map((button) => (
          <Button
            id={`documento${button.id}`}
            key={`file${button.id}`}
            sx={{
              mt: 2,
              ...(button.date && dayjs(button.date) > dayjs(currentDate) && {visibility: 'hidden'})
            }}
            color={button.color || "primary"}
            variant={button.variant || "outlined"}
            onClick={() => handleOpenFile(button)}
          >
            {button.label}
          </Button>
        ))}
        <Button sx={{ mt: 2 }} variant="contained" onClick={handleGoToApplication}>Regístrate aquí</Button> */}
        {open && (
          <FileViewer
            open={open}
            setOpen={setOpen}
            src={selectedFile.src}
            label={selectedFile.label}
            type={selectedFile.type}
          />
        )}
        <Testimonios />
      </Stack>
    </Stack>
  );
}
