import React from 'react';
import { Stack, Button } from '@mui/material';
import FileViewer from '../FileViewer';
import { styled } from '@mui/material/styles';

const A = styled('a')({
  color: 'inherit',
  cursor: 'pointer',
});

const ComponentFileViewer = (props) => {
  const { src, label, type, component } = props;
  const [open, setOpen] = React.useState(false);


  return (
    <Stack>
      {component === 'button' && (
        <Button variant="outlined" onClick={() => setOpen(true)}>{label}</Button>
      )}
      {component === 'link' && (
        <A onClick={() => setOpen(true)}>{label}</A>
      )}
      {open && (
        <FileViewer
          open={open}
          setOpen={setOpen}
          src={src}
          label={label}
          type={type}
        />
      )}
    </Stack>
  );
}

export default ComponentFileViewer;